import { BaseOperation } from "fast-json-patch";

export enum Signal {
    RED = "RED",
    YELLOW = "YELLOW",
    GREEN = "GREEN",
}
export enum Permission {
    CreateActivities = "create_activities",
    CreateCategories = "create_categories",
    CreateWorkpackages = "create_workpackages",
    CreateVersion = "create_version",
    CreateDraft = "create_draft",
    CreateTemplate = "create_template",
    CreateTodoList = "create_todolist",
    CreateTodos = "create_todos",
    DeleteActivities = "delete_activities",
    DeleteWorkpackages = "delete_workpackages",
    DeleteTodoList = "delete_todolist",
    DeleteTodos = "delete_todos",
    FreezeWorkpackages = "freeze_workpackages",
    DeleteCategories = "delete_categories",
    DeleteTemplate = "delete_template",
    DeleteDraft = "delete_draft",
    ReadActivities = "read_activities",
    ReadCategories = "read_categories",
    ReadUsers = "read_users",
    ReadWorkpackages = "read_workpackages",
    ReadDrafts = "read_drafts",
    ReadVersion = "read_version",
    ReadTemplate = "read_template",
    ReadTemplateList = "read_templatelist",
    UpdateActivities = "update_activities",
    ReadTodoList = "read_todolist",
    UpdateCategories = "update_categories",
    UpdateWorkpackagesControlinfo = "update_workpackages-controlinfo",
    UpdateWorkpackages = "update_workpackages",
    UpdateWorkpackagesCommissioner = "update_workpackages-commissioner",
    UpdateWorkpackagesMetaInfo = "update_workpackages-metainfo",
    UpdateWorkpackagesResponsible = "update_workpackages-responsible",
    UpdateWorkpackagesInvolved = "update_workpackages-involved",
    UpdateWorkpackagesDone = "update_workpackages-done",
    UpdateWorkpackagesDecision = "update_workpackages-decision",
    UpdateWorkpackagesPlannedStart = "update_workpackages-plannedstart",
    UpdateWorkpackagesPlannedFinish = "update_workpackages-plannedfinish",
    UpdateActivitySort = "update_activitysort",
    UpdateActivitySortIfResponsible = "update_activitysort-ifresponsible",
    UpdateTodoList = "update_todolist",
    UpdateTodos = "update_todos",
    UpdateTemplate = "update_template",
    RoleResponsible = "role_responsible",
    RoleCommissioner = "role_commissioner",
    RoleUserManager = "role_usermanager",
    RoleProjectManager = "role_projectmanager",
    DownloadXlsx = "download_xlsx",
    AdminRestorebackup = "admin_restorebackup",
    UpdateTodoSortIfResponsible = "update_todosort-ifresponsible",
    UpdateTodoSort = "update_todosort",
}

export enum EapContext {
    Code = "<Code>",
    ActivityId = "<ActivityId>",
    Version = "<Version>",
    TemplateName = "<TemplateName>",
    TodoListId = "<TodoListId>",
}
export enum EapAtoms {
    CategoryInfo = "CategoryInfo",
    CategoryLatest = "CategoryLatest",
    WorkpackageInfo = "WorkpackageInfo",
    WorkpackageLatest = "WorkpackageLatest",
    ProjectTree = "ProjectTree",
    ProjectTreeSnapshot = "ProjectTreeSnapshot",
    VersionList = "VersionList",
    Draft = "Draft",
    ProjectUsers = "ProjectUsers",
    PersonalUserData = "PersonalUserData",
    ProjectActivity = "ProjectActivity",
    TemplateInfo = "TemplateInfo",
    TemplateLatest = "TemplateLatest",
    TemplateList = "TemplateList",
    TodoListList = "TodoListList",
    TodoListInfo = "TodoListInfo",
    TodoListLatest = "TodoListLatest",
}
export interface IProjectTree {
    entries: IProjectTreeElement[];
}
export interface IWorkpackage {
    /**
     * @default false
     */
    frozen: boolean;

    /**
     * @default ""
     */
    label: string;

    /**
     * @default null
     */
    responsibleUserId: UserPublicID | null;

    /**
     * @default null
     */
    commissionerUserId: UserPublicID | null;

    /**
     * @default null
     */

    involvedUserIds: UserPublicID[] | null;

    /**
     * @default null
     */
    plannedStart: Date | null;

    /**
     * @default null
     */
    plannedFinish: Date | null;

    /**
     * @default ""
     */
    description: string;

    /**
     * @default ""
     */
    objective: string;

    /**
     * @default ""
     */
    goals: string;

    /**
     * @default null
     */
    dependentWPs: string[] | null;

    /**
     * @default ""
     */
    status: string;

    /**
     * @default Signal.GREEN
     */
    signal: Signal;

    /**
     * @default 0
     */
    progress: number;

    /**
     * @default ""
     */
    progressReason: string;

    /**
     * @default false
     */
    decisionRequired: boolean;

    /**
     * @default ""
     */
    decisionRequiredReason: string;

    /**
     * @default ""
     */
    decision: string;

    /**
     * @default false
     */
    done: boolean;

    /**
     * @default null
     */
    expectedFinish: Date | null;

    /**
     * @default null
     */
    activities: Record<string, IActivity> | null;

    /**
     * @default false
     */
    deleted: boolean;
}
export type ICategoryLog = VersionedAtomData<ICategory>;
export type ListAtomDataType<ValueType> = { entries: Array<ValueType> };
export interface ChangeLog<T = unknown> {
    changes: TypedOperation<T>[];
    author: UserPublicID;
    date: Date;
}
/**
 * this is a trick to get type safety
 * it will give an error string is not assignable to "user_public_id_placeholder" when trying to assing any string
 * it can still be used as index for dictionaries expecting a string as input
 */
export type UserPublicID = "user_public_id_placeholder" | "user_public_id_placeholder1";

export interface IActivity {
    /**
     * @default crypto.randomUUID()
     */
    uuid: string;

    /**
     * @default ""
     */
    description: string;

    /**
     * @default ""
     */
    status: string;

    /**
     * @default null
     */
    plannedFinish: Date | null;

    /**
     * @default null
     */
    expectedFinish: Date | null;

    /**
     * @default null
     */
    currentFinish: Date | null;

    /**
     * @default ""
     */
    label: string;

    /**
     * @default null
     */
    sortIndex: number | null;

    /**
     * @default false
     */
    deleted: boolean;
}
export interface ICategory {
    label: string;

    /**
     * @default ""
     */
    description: string;
    deleted: boolean;
}
export type IVersionList = ListAtomDataType<IVersion>;
export interface IVersion {
    label: string;
    date: Date;
    author: UserPublicID;
    description: string;
}
export type VersionedAtomData<T extends AtomData = AtomData> = {
    __history: Array<ChangeLog<T>>;
};
export type AtomData = { [key: string]: any };
export type TypedOperation<IType> = Operation & {
    path: `/${Paths<IType>}`;
};
export type Paths<T, D extends number = 10> = [D] extends [never]
    ? never
    : T extends object
    ? {
          [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
      }[keyof T]
    : "";
type Join<K, P> = K extends string | number
    ? P extends string | number
        ? `${K}${"" extends P ? "" : "/"}${P}`
        : never
    : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, ...0[]];
export type IWorkpackageLog = VersionedAtomData<IWorkpackage>;
export enum DraftItemType {
    AddCategory = "AddCategory",
    MoveCategory = "MoveCategory",
    DeleteCategory = "DeleteCategory",
    AddWorkpackage = "AddWorkpackage",
    MoveWorkpackage = "MoveWorkpackage",
    DeleteWorkpackage = "DeleteWorkpackage",
    UpdateCategoryTitle = "UpdateCategoryTitle",
    UpdateWorkpackageTitle = "UpdateWorkpackageTitle",
    RestoreWorkpackage = "RestoreWorkpackage",
    RestoreCategory = "RestoreCategory",
}
export type IDraftAtom = ListAtomDataType<IDraft>;
export interface IDraft {
    type: DraftItemType;
    code: string;
    label?: string;
    to?: string;
    description?: string;
}
export type IProjectUsers = ListAtomDataType<IProjectUser>;
export interface IProjectUser {
    userId: UserPublicID;
    name: string;
    email: string;
    permissions: string[];
}
export interface IPrivateUserData {
    email: string | null;
    lastSignInAt: Date | null;
    displayName: string | null;
    permissions: string[];
    // firebaseUserData: IFirebaseUserData | null;
}

export const createActivityDraft = (): IActivity => ({
    uuid: crypto.randomUUID(),
    description: "",
    status: "",
    plannedFinish: null,
    expectedFinish: null,
    currentFinish: null,
    label: "",
    sortIndex: null,
    deleted: false,
});

export type IWorkpackageCompact = Omit<IWorkpackage, "activities"> & {
    allActivities: number;
    finishedActivities: number;
    combinedProgress: number;
    lastActivityFinishedAt: Date | null;
} & VersionedMetaInfo;
export interface VersionedMetaInfo {
    deleted: boolean;
    author: UserPublicID;
    date: Date;
}
export type IProjectActivityLog = ListAtomDataType<IProjectActivity> & {
    writeIndex: number;
};
export interface IProjectActivity {
    code: string;
    author: UserPublicID;
    date: Date;
    label?: string;
    type: ActivityType;
}
export enum ActivityType {
    CreateWorkpackage = "CreateWorkpackage",
    UpdateWorkpackage = "UpdateWorkpackage",
    DeleteWorkpackage = "DeleteWorkpackage",
    UndeleteWorkpackage = "UndeleteWorkpackage",
    FreezeWorkpackage = "FreezeWorkpackage",
    UnfreezeWorkpackage = "UnfreezeWorkpackage",
    CreateCategory = "CreateCategory",
    UpdateCategory = "UpdateCategory",
    DeleteCategory = "DeleteCategory",
    UndeleteCategory = "UndeleteCategory",
    CreateTodoList = "CreateTodoList",
    UpdateTodoList = "UpdateTodoList",
    DeleteTodoList = "DeleteTodoList",
    UndeleteTodoList = "UndeleteTodoList",
}
export interface ITemplateInfo {
    name: string;
    templateData: JSON;
    deleted: boolean;
    draft: boolean;
}
export type ITemplates = ListAtomDataType<ITemplateInfo>;
export type ITemplateList = ListAtomDataType<ITemplateListElement>;
export type ITemplateLog = VersionedAtomData<ITemplateInfo>;
type ITemplateListElement = Pick<ITemplateInfo, "name" | "draft">;
export type IProjectTreeElement<ElementType = ICompactCategory | IWorkpackageCompact> = {
    elementType: ProjectTreeElementType;
    code: string;
} & ElementType;
export type ICompactCategory = {
    code: string;
    label?: string;
    description?: string;
} & VersionedMetaInfo;
export interface ITodoListList {
    entries: ITodoListCompact[];
}
export interface ITodoList {
    /**
     * @default crypto.randomUUID()
     */
    uuid: string;

    /**
     * @default ""
     */
    label: string;

    /**
     * @default null
     */
    responsibleUserId: UserPublicID | null;

    /**
     * @default null
     */

    involvedUserIds: UserPublicID[] | null;

    /**
     * @default ""
     */
    description: string;

    /**
     * @default null
     */
    dependentWbsElements: string[] | null;

    /**
     * @default null
     */
    todos: Record<string, IToDo> | null;

    /**
     * @default false
     */
    deleted: boolean;

    /**
     * @default file
     */
    done: boolean;
}
export type ITodoListLog = VersionedAtomData<ITodoList>;
export interface IToDo {
    /**
     * @default crypto.randomUUID()
     */
    uuid: string;

    /**
     * @default ""
     */
    description: string;

    /**
     * @default false
     */
    done: boolean;

    /**
     * @default ""
     */
    status: string;

    /**
     * @default null
     */
    plannedFinish: Date | null;

    /**
     * @default ""
     */
    label: string;

    /**
     * @default null
     */
    sortIndex: number | null;

    /**
     * @default false
     */
    deleted: boolean;
}
export type ITodoListCompact = Omit<ITodoList, "todos"> &
    VersionedMetaInfo & {
        computedProgress: number;
    };

export const createTodoDraft = (): IToDo => {
    return {
        uuid: crypto.randomUUID(),
        description: "",
        status: "",
        plannedFinish: null,
        label: "",
        sortIndex: null,
        deleted: false,
        done: false,
    };
};

export enum ProjectTreeElementType {
    Category = "category",
    Workpackage = "workpackage",
}
export const getNewSubCode = (
    parentCode: string,
    drafts: IDraft[],
    treeElements: IProjectTreeElement[],
): string | undefined => {
    if (!parentCode) {
        return;
    }
    let newSubCode: string | undefined;
    const level = parentCode.split(".").length;
    const isCodeAvailable = (newSubCode: string) => {
        const possibleCode = parentCode + "." + newSubCode;

        if (
            !drafts.find((entry) => entry.code === possibleCode) &&
            !treeElements.find((entry) => entry.code === possibleCode)
        ) {
            return true;
        }
        return false;
    };
    let possibleCode: string | undefined;
    switch (level) {
        case 1:
            for (let i = 0; i < 26; i++) {
                newSubCode = String.fromCharCode(65 + i);
                if (isCodeAvailable(newSubCode)) {
                    possibleCode = parentCode + "." + newSubCode;
                    break;
                }
            }
            break;
        case 2:
        case 3:
            for (let i = 1; i < 10; i++) {
                newSubCode = i.toString();
                if (isCodeAvailable(newSubCode)) {
                    possibleCode = parentCode + "." + newSubCode;
                    break;
                }
            }
            break;
        default:
            for (let i = 1; i < 10; i++) {
                newSubCode = (i * 10).toString();
                if (isCodeAvailable(newSubCode)) {
                    possibleCode = parentCode + "." + newSubCode;
                    break;
                }
            }
            break;
    }
    if (!possibleCode) return parentCode;

    return possibleCode;
};
export function generateDraftOperationAndScopeMap(): Map<
    DraftItemType,
    { operation: DraftOperationType; scope: DraftScopeType }
> {
    const map = new Map();

    for (const key in DraftItemType) {
        const itemType = DraftItemType[key as keyof typeof DraftItemType];
        const [operation, scope] = itemType.split(/(?=[A-Z])/).map((s) => s.replace(/[^A-Za-z]/g, ""));

        map.set(itemType, { operation: operation as DraftOperationType, scope: scope as DraftScopeType });
    }

    return map;
}
export type DraftOperationType = "Add" | "Move" | "Update" | "Delete" | "Restore";
export type DraftScopeType = "Workpackage" | "Category";
export function getDraftOperation(itemType: DraftItemType): DraftOperationType | undefined {
    return generateDraftOperationAndScopeMap().get(itemType)?.operation;
}
export function getDraftScope(itemType: DraftItemType): DraftScopeType | undefined {
    return generateDraftOperationAndScopeMap().get(itemType)?.scope;
}
export interface ITestScenario {
    workPackages: IWorkpackage[];
    categories: ICategory[];
    drafts: IDraft[];
    versions: IVersion[];
}

export const createTodoListDraft = (): ITodoList => ({
    uuid: crypto.randomUUID(),
    label: "",
    responsibleUserId: null,
    involvedUserIds: null,
    description: "",
    todos: {},
    deleted: false,
    done: false,
    dependentWbsElements: null,
});

export const createWorkpackageDraft = (code: string): IWorkpackage => ({
    frozen: false,
    label: "",
    responsibleUserId: null,
    commissionerUserId: null,
    involvedUserIds: null,
    plannedStart: null,
    plannedFinish: null,
    description: "",
    objective: "",
    goals: "",
    dependentWPs: null,
    status: "",
    signal: Signal.GREEN,
    progress: 0,
    progressReason: "",
    decisionRequired: false,
    decisionRequiredReason: "",
    decision: "",
    done: false,
    expectedFinish: null,
    activities: null,
    deleted: false,
});

export declare type Operation =
    | AddOperation<any>
    | RemoveOperation
    | ReplaceOperation<any>
    | MoveOperation
    | CopyOperation
    | TestOperation<any>
    | GetOperation<any>;
export interface AddOperation<T> extends BaseOperation {
    op: "add";
    value: T;
}
export interface RemoveOperation extends BaseOperation {
    op: "remove";
}
export interface ReplaceOperation<T> extends BaseOperation {
    op: "replace";
    value: T;
}
export interface MoveOperation extends BaseOperation {
    op: "move";
    from: string;
}
export interface CopyOperation extends BaseOperation {
    op: "copy";
    from: string;
}
export interface TestOperation<T> extends BaseOperation {
    op: "test";
    value: T;
}
export interface GetOperation<T> extends BaseOperation {
    op: "_get";
    value: T;
}
